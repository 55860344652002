<template>
  <main class="kb-main" id="kb-checklist">
    <LxpMobileHeader :use-route-name="true" />

    <div class="main-content min-component pb-3" v-if="!isLoading">
      <template v-if="paging.totalCount > 0">
        <div class="list-top">
          <div class="top-column">
            <p class="text text-muted">총 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(dist,idx) in dists" :key="idx" class="board-list-item">
              <div class="w-100" @click="moveToDtl(dist.distCrseSn)">
                <div class="board-column column-title"><span class="title">{{dist.distCrseNm}}</span></div>
                <div class="board-column column-date"><span class="text">{{timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd')}} ~ {{timestampToDateFormat(dist.endDt, 'yyyy.MM.dd')}}</span></div>
              </div>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
        </div>
      </template>
      <template v-else>
        <div class="error-page mt-3">
          <div class="images">
            <img src="@/assets/lxp/images/common/img_empty.png" />
          </div>
          <p class="description">운영중인 과정차수가 없습니다.</p>
        </div>
      </template>
    </div>

    <div class="main-bottom"></div>
  </main>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import mobileUtils from "@/assets/js/navigationUtils";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {ACT_GET_CRSE_DIST_LIST_BY_OPER_ID} from "@/store/modules/checklist/checklist";
import {goBackFunc} from "@/assets/js/modules/common/common";
import {getItems, getPaging, initPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";

export default {
  name: 'Checklist',
  components: {LxpMobileHeader, CommonPaginationFront},
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const {showMessage} = useAlert()
    const isLoading = ref(true)
    const dists = ref([])
    const paging = ref(initPaging(route))

    const moveToDtl = (distCrseSn) => {
      // router.push({name: 'ChecklistDtl', params:{'distCrseSn': distCrseSn}})
      router.push({name: 'ChecklistMain', params:{'distCrseSn': distCrseSn}, query:{'view': 'attend'}})
    }

    const pagingFunc = (pageNo, pageSize) => {
      paging.value.pageNo = pageNo;
      paging.value.pageSize = pageSize;
      getCrseDistListByOperId();
    }

    const getCrseDistListByOperId = () => {
      isLoading.value = true;
      store.dispatch(`checklist/${ACT_GET_CRSE_DIST_LIST_BY_OPER_ID}`, {
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then((res) => {
        if(lengthCheck(res)){
          dists.value = getItems(res);
          paging.value = getPaging(res);
        }else{
          dists.value = [];
          paging.value.totalCount = 0;
        }
      }).finally(() => {
        isLoading.value = false;
      })
    }

    onMounted(() => {
      // 웹화면으로 지원되지 않음
      if(mobileUtils.any()){
        getCrseDistListByOperId();
      }else{
        showMessage('모바일에서만 접근 가능한 화면입니다.');
        goBackFunc();
      }
    })

    return {
      isLoading,
      dists,
      paging,
      moveToDtl,
      pagingFunc,
      timestampToDateFormat,
    }
  }
}
</script>